<template>
  <the-form :form="form" :store="store" class="crud form" :id="id" :edit="edit" :entity="entity" :title="title"
    :submit="submit"></the-form>
</template>

<script>
import Rules from '@/components/crud/rules';
import TheForm from '@/components/crud/TheForm'
import timezones from '@/store/timezones'
export default {
  props: {
    entity: {
      required: false,
    },
    id: {
      required: false,
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      store: "organizations",
      form: {
        nodes: [
          { component: "h3", label: this.$t('common.basic_information') },
          { component: "input", label: this.$t('common.name'), index: "name", required: true },
          { component: "select", index: "timezone", label: this.$t("common.timezone"), required: true, values: timezones.map(timezone => ({ label: timezone, value : timezone }))},
          {
            component: "input", label: this.$t('configuration.organization.ipaddress_pbx'), index: "pbxDomainAlt", required: true, className: "childrenRows", validations: [
              { validator: Rules.isIpAddress, trigger: 'blur' }
            ],
            children: [
              { component: "select", index: "firebase", label: this.$t('configuration.organization.firebase_server'), required: true, store: "firebases", "label-value": "name" },
              { component: "select", index: "mediaServerAlt", label: this.$t('configuration.organization.janus_server'), required: true, store: 'mediaservers', "label-value": "name" },
              { component: "select", index: "mediaServers", label: "Media Severs (Pool)", store: 'mediaservers', multiple: true, "label-value": "name" },
            ]
          },
          {
            component: "h3", label: this.$t('common.restApi'), children: [
              { component: "input", label: this.$t('configuration.organization.pbxRestApi'), index: "pbxRestApi", },
              { component: "input", label: this.$t('configuration.organization.pbxRestApiUsername'), index: "pbxRestApiUsername", },
              { component: "input", label: this.$t('configuration.organization.pbxRestApiPassword'), index: "pbxRestApiPassword", },
            ]
          },
          { component: "input", index: "stunServer", label: "Stun Server" },
          { component: "input", label: this.$t('configuration.organization.data_set'), index: "bigQuery", required: false, value: process.env.VUE_APP_DATA_SET ?? ""  },
          { component: "input", label: this.$t('configuration.organization.limit_sessions_active'), index: "sessions", required: true, type: "number" },
          { component: "radio", label: this.$t('configuration.organization.use_contact_me'), index: "callCenter", values: [{ label: 'Yes', value: true }, { label: 'No', value: false }], value: false, hideIndex: "callcenter" },
          {
            component: "h3", label: this.$t('configuration.organization.configuration_of_contact_me'), hidden: true, index: "callcenter", children:
              [
                {
                  component: "input", label: this.$t('configuration.organization.max_amount_of_agents_connected'), type: "number", className: "childrenRows", index: "agentSessions",
                  children:
                    [
                      { component: "select", label: this.$t('configuration.organization.select_mail_server'), store: "ostickets", index: "osticket", nulleable: true, "label-value": "name" },
                      { component: "select", label: this.$t('configuration.organization.select_chat_server'), store: "livehelperchats", index: "LiveHelperChat", nulleable: true, "label-value": "name" },
                      { component: "radio", label: "¿Call Center?", index: "dataSetAlt", values: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }], value: 'no' },
                      { component: "input", label: this.$t('configuration.organization.chatApiKey'), index: "chatApiKey", },
                      { component: "input", label: this.$t('configuration.organization.agentActivityTimeout'), index: "agentActivityTimeout", type: "number", value: 1440 },
                      { component: "input", label: this.$t('configuration.organization.callCenterApiKey'), index: "callCenterApiKey", type: "password", value: "" },
                      { component: "input", label: this.$t('configuration.organization.callCenterId'), index: "callCenterId", type: "string", value: "" },
                      { component: "input", label: this.$t('configuration.organization.callCenterApiUrl'), index: "callCenterApiUrl", type: "sting", value: "" },
                    ]
                }
              ]
          },
          { component: "radio", label: this.$t('configuration.organization.enableCustomers'), index: "customersEnabled", values: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], value: 0 },
        ]
      }
    }
  },
  components: {
    TheForm
  },
  computed: {
    edit() {
      return this.id !== "0"
    },
    title() {
      return this.edit ? this.$t("configuration.organization.update") : this.$t("configuration.organization.create")
    }
  },
  methods: {
    submit(data) {
      if (data.agentSessions === "") {
        data.agentSessions = 0
      }
      return data;
    }
  }
}
</script>

<style>
.form>div {
  width: 50%;
}

.childrenRows .childrens {
  display: inline-flex;
  gap: 16px;
  flex-wrap: wrap
}
</style>