const state = {
  chats: [],
  alreadyListening: false
}

const getters = {
  getActivityFromAgent: (state) => (agentNumber) => {
    return state.chats.filter(chat => chat.agent_number === agentNumber)
  }
}

const mutations = {
  TOGGLE_ALREADY_LISTENING_LIST: (state) => (state.alreadyListening = !state.alreadyListening),
  ADD_CHAT: (state, chat) => {
    if (state.chats.find(_chat => _chat.id === chat.id)) {
      state.chats = state.chats.map(chat => chat.id === chat.id ? {...chat, ...chat } : chat)
    } else {
      state.chats.push(chat)
    }
  },
  UPDATE_CHAT: (state, chat) => state.chats = state.chats.map(_chat => _chat.id === chat.id ? {..._chat, ...chat } : _chat),
  REMOVE_CHAT: (state, chat) => state.chats = state.chats.filter(_chat => _chat.id !== chat.id),
}

const actions = {
  listenAgentChatDashboard({ rootGetters, commit, state  }, { organizationId , date = new Date()}) {

    if (state.alreadyListening) {
      return
    }

    commit('TOGGLE_ALREADY_LISTENING_LIST')

    const firebase = rootGetters.getFirebase

    firebase.firestore().collection(`livehelperchat/${organizationId}/chats`).where('createdAt', '>', date).onSnapshot((snapshot) => {
      for (const change of snapshot.docChanges()) {
        const chat = { ...change.doc.data(), id: change.doc.id }
        if (change.type === "added" && chat.status === 1) {
          commit('ADD_CHAT', chat)
        }

        if (change.type === "modified") {
          commit('UPDATE_CHAT', chat)
        }

        if (change.type === "removed") {
          commit('REMOVE_CHAT', chat)
        }
      }     
    })

    firebase.firestore().collection(`livehelperchat/${organizationId}/chats`).where('closed_at', '>', (date.getTime() / 1000)).onSnapshot((snapshot) => {
      for (const change of snapshot.docChanges()) {
        const chat = { ...change.doc.data(), id: change.doc.id }
        if (change.type === "added" && chat.status === 2) {
          commit('ADD_CHAT', chat)
        }
      }     
    })
  }
}

export default {
  namespaced : true,
  state,
  getters,
  mutations,
  actions
} 