<template>
    <div v-if="row.details"> 
      <el-popover trigger="hover">
        <TheList :columns="columns" :value="value[row['load-from']]" />
        <p slot="reference" class="mb-0" style="cursor: pointer">{{ val }}</p>
      </el-popover>
    </div>
    <div v-else style="display:inline-flex; flex-direction: row; align-items: center; gap: var(--column)">
      <el-avatar v-if="row.withAvatar" :src="value[row.withAvatar]">
        <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
      </el-avatar>
      <div v-if="type === 'stringWithImage'" class="string-with-image">
        <el-popover
          placement="left"
          width="400"
          trigger="hover">
          <img :src="value[row.src]" v-if="value[row.src]"/>
          <img class="thumbnail" slot="reference" :src="value[row.src]" v-if="value[row.src]"/>
        </el-popover>
        <p slot="reference" class="mb-0">{{ val }}</p>  
      </div>
      <multi-tags v-else-if="type === 'tags'" size="small" :max="1" :data="getMultiTags(val)" />
      <el-tag v-else-if="type === 'tag'" size="small" :max="1" :type="value.tagType ?? 'info'">{{ val }}</el-tag>
      <el-tag v-else-if="type === 'boolean'" size="small" :type="val === 'Yes' ? 'success' : 'danger'">{{ val }}</el-tag>
      <div  v-else-if="type === 'img'">
        <el-popover
          placement="left"
          width="400"
          trigger="hover"
          v-if="val">
          <img :src="val"/>
          <img class="thumbnail" slot="reference"  :src="val" v-if="val"/>
        </el-popover>
      </div>
      <p slot="reference" class="mb-0" v-else-if="type === 'date'">{{ val  }}</p>
      <div v-else-if="type === 'agents'" class="agents">
        <div v-for="(agent, index) in val" :key="index" class="agent">
          <el-avatar :src="agent.user.picture">
            <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
          </el-avatar>
          <div>
            <p class="mb-0">{{ agent.user.name }} {{ agent.user.lastname }}</p>
            <p class="mb-0">Agent/{{ agent.number   }}</p>
          </div>
        </div>
      </div>
      <p slot="reference" class="mb-0" v-else>{{ val }}</p>
    </div>
</template>

<script>
import TheList from './TheList'
import MultiTags from '@/components/common/MultiTags'
import UserInfo from '@/views/users/user-info'
export default {
  name : 'TheCell',
  props: {
    row: {
      required: true,
    },
    value : {
      required : true,
    },
  },
  data() {
    return {
      translate: this.row.translate ? this.row.translate : null, 
      type : this.row.type ? this.row.type : 'text',
      info : {
        user : UserInfo 
      },
      columns : []
    }
  },
  methods : {
    getMultiTags(values) {
      if (Array.isArray(values)) {
        return values.map(value => ({ value }))
      }
      return []
    },
    findValue(keys, haystack) {
      if (keys.length === 0) {
        return haystack;
      }
      
      let value;
      const remainingKeys = [...keys];  // Crear una copia de keys
      
      for (let key of remainingKeys) {
        for (let i in haystack) {
          if (key === i) {
            remainingKeys.shift(); // Modificar la copia, no el original
          
            if (Array.isArray(haystack[i])) {
              value = haystack[i].map(item => {
                if (remainingKeys.length === 1) {
                  return item[remainingKeys[0]];
                }
                return "";
              });
            } else {
              value = this.findValue(remainingKeys, haystack[i]);
            }
          }
        }
      }
      
      return value;
    },
    loadInfo() {
      this.columns = this.info[this.row['load-from']]
    }
  },
  computed : {
    val() {
      /*
      * traslate espera un namespace: configuration.saraza. 
      * retorna trans( configuration.sazara.COLUMN_VALUE )
      */  
      if( this.translate ){
            return this.translatedValue
      }

      if (this.type === 'boolean') {
        return this.booleanValue
      }

      if (this.type === 'time') {
        const valueInSeconds = this.value[this.row.index];
        const hours = Math.floor(valueInSeconds / 3600);

        return hours === 0 
          ? new Date(valueInSeconds * 1000).toISOString().substr(11, 8) 
          : '00:' + new Date(valueInSeconds * 1000).toISOString().substr(14, 5);
      }

      if (this.type === 'join') {
        const values = this.row.options.map(option => {
          if (option.indexOf('.')) {
            const options = option.split(".");
            return this.findValue(options, this.value)
          }
        })
        return values.join(this.row.joinBy ? this.row.joinBy : " ")
      }

      if (this.type === 'tags' && this.row.options && this.value[this.row.index] != undefined ) {
        const values = this.value[this.row.index].map(value => {
          return this.row.options.reduce((values, curr) => {

            if (value[curr]) {
              values.push(value[curr])
            } else {
              values.push(curr.indexOf('.') > -1  ? this.findValue(curr.split('.'), value) : curr)
            }

            return values
          }, []).join('')
        })
        return values
      }


      if (this.type === 'agents') {
        return this.value[this.row.index];
      }
 
      return this.row.index.indexOf('.') > -1 ? this.findValue(this.row.index.split('.'), this.value) :  this.value[this.row.index]
    },
    translatedValue(){
        const translateIndex = `${this.translate}.${this.value[this.row.index]}`;
        return this.$t( translateIndex );
    },
    booleanValue() {
      const value = this.value[this.row.index]
      const empty = [undefined, null, 0, '0', "", false, "false", "no"]

      if (empty.includes(value)) {
        return 'No'
      }
    
      return "Yes";
    },
    tagType() {
      return "ho"
    }
  },
  components : {
    MultiTags,
    TheList
  },
  created() {
    if (this.row.details && this.row['load-from']) {
      this.loadInfo()
    }
  }
};
</script>

<style scoped>
.string-with-image {
  display: inline-flex;
  align-items: center;
}

img {
  width: 80%;
  aspect-ratio: 3 / 2;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

img.thumbnail {
  width: 50%;
}

.agents {
  display: flex;
  flex-direction: column;
  gap: var(--column)
}

.agent {
  display: inline-flex;
  gap: calc(var(--column) / 2)
}

</style>