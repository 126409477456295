<template>
  <div>
    <div v-if="contact" style="width: 100%; display: flex; align-items: center; flex-direction: column;">
      <user-item :user="contact" :show-fullname="false" :show-status="false" style="width: auto" />
      <p class="text-center name">{{ contact.visiblename }}</p>
    </div>
    <p v-else>{{displayName}} <br> {{ callerid }}</p>
    <p v-if="callDate">
      <chronometer :timestamp="callDate.getTime()"></chronometer>
      <the-ping :stats="stats" :janus="janus"></the-ping>
    </p>
    <div class="keypad">
      <hold-button class="button" :janus="janus" />
      <mute-button class="button" :janus="janus" />
      <el-tooltip effect="dark" :content="$t('call.transfer')" placement="bottom">
        <button class="info button" @click="showTransfer = !showTransfer; showKeypad = false"><i
            class="fas fa-exchange-alt"></i></button>
      </el-tooltip>
      <button class="button info" @click="showKeypad = !showKeypad; showTransfer = false"><span
          class="icon-hiperme_iconos95"></span></button>
    </div>
    <hangup-button :janus="janus" class="button hangup-button" />
    <the-keypad :only-numpad="true" class="only-numpad" v-if="showKeypad"></the-keypad>
    <the-transfer class="transfer" v-if="showTransfer"></the-transfer>
  </div>
</template>

<script>
import ThePing from "@/components/webrtc/ThePing";
import { mapGetters } from 'vuex'
import UserItem from '../common/UserItem';
import HoldButton from '@/components/webrtc/HoldButton'
import MuteButton from '@/components/webrtc/MuteButton'
import HangupButton from '@/components/webrtc/HangupButton'
import TheKeypad from "./TheKeypad"
import TheTransfer from "./TheTransfer"
import Chronometer from '@/components/common/Chronometer'
export default {
  components: {
    ThePing,
    UserItem,
    HoldButton,
    MuteButton,
    HangupButton,
    TheKeypad,
    TheTransfer,
    Chronometer
  },
  props: {
    displayName: {
      required: true,
      default: null,
    },
    janus: {
      required: true
    },
    callerid: {
      required: true
    },
    callDate: {
      required: true
    }
  },
  data() {
    return {
      stats: null,
      showKeypad: false,
      showTransfer: false
    }
  },
  computed: {
    ...mapGetters('contacts', ['getContactByCallerId']),
    contact() {
      return this.getContactByCallerId(this.callerid)
    }
  }
}
</script>

<style scoped>
.keypad {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: space-around;
  margin: 0 auto;
}

p {
  color: white;
  font-weight: bold;
  text-align: center;
}

.light p,
.clasic p {
  color: var(--hiperpbx-green);
}

.hangup-button {
  margin: 0 auto;
  display: block;
}

.only-numpad:deep(.keypad) {
  width: 90%;
  margin: 0 auto;
  margin-top: var(--column)
}


.button,
.button:deep(button),
.only-numpad:deep(.keypad) button {
  height: 60px;
  width: 60px;
}

.clasic .button {
  background-color: #e1e1e1;
  color: #030303;
}

.button {
  margin-bottom: var(--column)
}
</style>