<template>
  <div class="main-view" :class="theme" @click="pageClicked" v-if="!loading">
    <the-main-menu v-if="user.uid" />
    <the-left-menu></the-left-menu>
    <main id="app-main-content">
      <keep-alive include="AgentConsole">
        <router-view class="main-view">
          <router-view></router-view>
        </router-view>
      </keep-alive>
    </main>
    <the-message-box v-for="(alert, i) in messages" :key="i" :message="alert.message" :type="alert.type" @close="close(i)" :style="{ 'top' : (84 * i) + 'px' }"></the-message-box>
    <the-group-modal></the-group-modal>
    <the-group-modal-members></the-group-modal-members>
    <the-group-modal-delete></the-group-modal-delete>
    <the-group-modal-leave></the-group-modal-leave>
    <the-group-modal-edit></the-group-modal-edit>
    <IncomingVideocall
        v-for="videocall in videoCallNotifications"
        :key="videocall.id"
        :display-name="videocall.from"
        :index="videocall.id"
        :room-id="videocall.roomId"
      />
    <the-min-chat-display />
    <the-already-opentab></the-already-opentab>
    <TheChangePassordByExpirationModal></TheChangePassordByExpirationModal>
    <audio ref="silence" loop>
      <source src="sounds/silence.wav" type="audio/wav"/>
    </audio>
    <audio id="agent-notifications">
			<source
				src="/sounds/appointed.mp3"
				type="audio/ogg"
			/>
		</audio>
        <audio id="agent-pending-chat">
			<source
				src="/sounds/done-for-you-612.mp3"
				type="audio/ogg"
			/>
		</audio>
    <video id="agentConsoleAudio" autoplay playsinline style="display: none" loop></video>
    <video id="corpoCallAudio" autoplay playsinline style="display: none" loop></video>
  </div>
  <div v-else v-loading.fullscreen.lock="loading" element-loading-text="Loading data" element-loading-background="white"></div>
</template>

<script>
import * as Sentry from "@sentry/vue"
import http from '@/api/user'
import { mapActions, mapGetters } from 'vuex'
import { secondsToHourMinutes } from '@/filters/dates'
import TheMainMenu from "@/components/menu/TheMainMenu"
import IncomingVideocall from "@/components/webrtc/IncomingVideocall"
import TheGroupModalEdit from "@/components/groups/TheGroupModalEdit"
import TheGroupModalLeave from "@/components/groups/TheGroupModalLeave"
import TheGroupModalDelete from "@/components/groups/TheGroupModalDelete"
import TheGroupModalMembers from "@/components/groups/TheGroupModalMembers"
import TheGroupModal from "@/components/groups/TheGroupModal"
import TheMessageBox from "@/components/common/TheMessageBox"
import TheMinChatDisplay from '@/components/chat/TheMinChatDisplay';
import TheAlreadyOpentab from '@/components/common/TheAlreadyOpentab'
import TheLeftMenu from '@/components/menu/TheLeftMenu'
import TheChangePassordByExpirationModal from '@/components/account/TheChangePassordByExpirationModal'
export default {
  data() {
    return {
      messages : [],
      promiseSound: null,
      loading: true,
      timeoutConnectionError: null,
    }
  },
  components : {
    TheMainMenu,
    TheGroupModalMembers,
    TheGroupModalDelete,
    TheGroupModalLeave,
    TheGroupModalEdit,
    IncomingVideocall,
    TheMessageBox,
    TheMinChatDisplay,
    TheGroupModal,
    TheAlreadyOpentab,
    TheLeftMenu,
    TheChangePassordByExpirationModal
  },
  methods : {
    ...mapActions("tours", ["listenTours"]),
    ...mapActions("audit", ["saveUserLogin"]),
    ...mapActions("contacts", ["listenContacts"]),
    ...mapActions("organization_contacts", { fetchOrganizationContacts: "all",}),
    ...mapActions("users", { fetchUsers: "all"}),
    ...mapActions("zoho", {listenZohoConfig : "listenConfig"}),
    ...mapActions([
      'cleanNotification',
      'firebaseLogout',
      'setInteractWithPage',
      'setCreateReportLogin',
      'fetchUserData',
      'saveFirebaseConfiguration',
      'initializeFirebaseApp',
      'listenToUser',
      'listenToNewNotifications',
      'updateData',
      'geTokenAndSaveIt'
    ]),
    assigned(instance, notification) {
      instance.close()
      this.$router.push({
        name : "ticket",
        params : { 
          ticketNumber : notification.ticket.number + ""
        }
      })
      this.cleanNotification(notification.id)
    },
    async checkInternetConnection() {
      try {
        const response = await http.ping()
        if (response.status === 200 && response.status < 300) {
          if (this.messages.find(message => message.type === 'error')) {
            this.showConnectionRestored()
          }
        } else {
          this.showConnectionError()
        }
      } catch (error) {
        this.showConnectionError()
      }

      setTimeout(() => {
        this.checkInternetConnection()
      }, 60000 * 5)
    },
    showConnectionError() {
      const last = this.messages[this.messages.length - 1]
      const type = (last) ? last.type : false
      if ( type !== 'error' || this.messages.length === 0) {
        this.messages.push({
          type : 'error',
          message : this.$t('common.internet-connection-has-been-lost', { hour : secondsToHourMinutes(new Date())  })
        })
      }
    },
    showConnectionRestored() {
      const last = this.messages[this.messages.length - 1]
      if (last.type !== 'success') {
        this.messages.push({
          type : 'success',
          message : this.$t('common.internet-connection-was-reestablished', { hour : secondsToHourMinutes(new Date())  })
        })
      }
    },
    close(i) {
      this.messages = this.messages.filter((message, index) => index !== i)
    },
    pageClicked() {
      this.handlePlaySilence()
      this.setInteractWithPage(true);
    },
    handlePlaySilence() {
      if (this.playSilence) {
        this.promiseSound = this.$refs.silence.play()
      } else {
        if (this.promiseSound) {
          this.promiseSound.then(() => {
            this.$refs.silence.pause()
            this.$refs.silence.currentTime = 0
            this.promiseSound = null;
          })
        }
      }
    },
    async start() {
      const firebaseConfig = await this.fetchUserData();
      this.saveFirebaseConfiguration(firebaseConfig)
      await this.initializeFirebaseApp()
      await this.updateData()
      this.listenToUser(this.user.uid);
      Sentry.setUser({ email: this.user.email });
      this.$store.dispatch("_users/fetchUsers");
      this.loading = false;
    },
  },
  computed : {
    ...mapGetters({
      user : 'getUser',
      theme : 'getTheme',
      chats : 'getOpenedChats',
      notifications : 'getNotifications',
      videoCallNotifications : 'getVideocallsNotifications',
      hasToLogout : 'getDoLogout',
      isLoggedFirebase  : 'isLoggedFirebase',
      playSilence : 'getPlaySilence',
      getInfoConnected: 'getInfoConnected',
      getFirebase: 'getFirebase',
    }),
    hasChat() {
      if (this.user) {
        return Object.keys(this.user.roles).includes("ROLE_USER") ||
          Object.keys(this.user.roles).includes("ROLE_ADMIN") ||
          this.user.roles.includes("ROLE_ADMIN") ||
          this.user.roles.includes("ROLE_USER")
      }
      return false
    },
    hasExtension() {
      return this.user.localExtension ? true : false
    },
    ticketNotifications() {
      return this.notifications.filter(notification => notification.type === 'tickets' && notification.active === true)
    },
    createReport() {
      return this.user.create_report;
    },
  },
  async created() {
    if (!this.getFirebase) {
      await this.start()
    } else {
      this.loading = false;
    }

    this.listenZohoConfig();
    this.listenTours()

    const organizations = this.user.organizations.map(organization => organization.id)
    const params = { "organizations.id" : organizations, isActive : 1 };
    this.listenContacts();
    this.fetchUsers(params);
    this.fetchOrganizationContacts(params);

    if (this.createReport) {
      this.setCreateReportLogin()
      await this.saveUserLogin()
    }

    if (this.$store.getters.getDesktopNotificationPermission) {
      this.geTokenAndSaveIt()
    }


  },
  watch : {
    hasToLogout(value) {
      if (value) {
        Sentry.setUser(null);
        this.$router.push({ name : "Logout"})
      }
    },
    getInfoConnected(value) {
      if (!value) {
        this.timeoutConnectionError = setTimeout(() => {
          this.showConnectionError();
          this.timeoutConnectionError = null;
        }, 60000 * 2)
      } else {
        if (this.timeoutConnectionError) {
          clearTimeout(this.timeoutConnectionError);
          this.timeoutConnectionError = null;
        } else {
          this.showConnectionRestored();
        }
      }
    }
  }
}
</script>

<style scoped></style>
