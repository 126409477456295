/* eslint-disable */
import auth from "@/api/auth";
import userapi from '@/api/user'

const defaultValues = () => ({
	name: "",
	lastname: "",
	uid: "",
	fullname: "",
	extension: "",
	hasVideoCall: false,
	status: "disconnected",
	email: "",
	picture: "",
	roles: [],
	organizations: [],
	language: 'es',
	theme: null,
	organization_session : {},
	create_report : false,
	id: 0,
	localExtension: null,
})

const state = {
	user: defaultValues(),
	users: [],
	videocall: null,
	usersWithoutAgent: [],
	doLogout : false,
	loggedFirebase : false,
	playSilence : true,
	presence: "web",
	actions: [],
	appAlreadyStarted: false,
	infoConnected: true,
};

const getters = {
	getUser : (state) => state.user,
	allUsers: (state) => state.users,
	getDoLogout : (state) => state.doLogout,
	allUsersWithoutAgent: (state) => state.usersWithoutAgent,
	isAdmin : (state) => state.user.roles.includes('ROLE_ADMIN'),
	isLoggedFirebase : (state) => state.loggedFirebase,
	getPlaySilence : (state) => state.playSilence,
	getPresence: (state) => state.presence,
	getHasChat : (state) => state.user.roles.includes('ROLE_ADMIN') || state.user.roles.includes('ROLE_USER'),
	getActions: (state) => state.actions,
	getInfoConnected: (state) => state.infoConnected,
	isManager: (state) => state.user.roles.includes('ROLE_MANAGER')
}; 

const mutations = {
	SET_USER: (state, user) => (state.user = {...state.user, ...user}),
	SET_USER_VIEW : (state, user) => state.userView = user,
	SET_USERS: (state, users) => (state.users = users),
	ADD_USER: (state, user) => (state.users.push(user)),
	UPDATE_USER: (state, user) => (state.users = [...state.users.filter(u => u.id !== user.id), user]),
	newVideocall: (state, videocall) => (state.videocall = videocall),
	SET_USERS_WITHOUT_AGENT: (state, users) => state.usersWithoutAgent = users,
	DO_LOGOUT : (state, value) => state.doLogout = value,
	SET_LOGGED_FIREBASE : (state, value) => state.loggedFirebase = value,
	SET_PRESENCE: (state, value) => state.presence = value,
	ADD_ACTION: (state, value)  => (state.actions.push(value)),
	REMOVE_ACTION: (state, id) => state.actions = state.actions.filter(action => action.id !== id),
	SET_APP_ALREADY_STARTED: (state, value) => state.appAlreadyStarted = value,
	SET_INFO_CONNECTED: (state, value) => state.infoConnected = value
};

// TODO
// Hay que cambiar un poco como funciona 
// este sistema, porque de esta forma
// se tiene acceso a otra información que sería privada
// para el usuario,

const actions = {
	listenToUser({ commit, rootState, dispatch }, uid) {
		const firebase = rootState.firebase.firebase;

		firebase.database().ref("logout").child(uid).on('value', (snap) => {
			commit('DO_LOGOUT', snap.val())
		})

		const isOfflineForDatabase = {
			status: 'disconnected',
			last_changed: firebase.database.ServerValue.TIMESTAMP,
			signIn : false,
			presence: "app"
		}

		const userRef = firebase.database().ref("users").child(uid)

		firebase.database().ref('.info/connected').on('value', (snapshot) => {
			if (state.appAlreadyStarted) {
				commit("SET_INFO_CONNECTED", snapshot.val());
			}

			if (snapshot.val() == false) {
				return
			}
			userRef.onDisconnect().update(isOfflineForDatabase).then(function () {
				const uniqueid = sessionStorage.getItem('uniqueid');
				userRef.update({
					status : localStorage.getItem('status') ? localStorage.getItem('status') : "connected",
					last_changed: firebase.database.ServerValue.TIMESTAMP,
					signIn : uniqueid,
					presence: "web"
				})
			})

			if (!state.appAlreadyStarted) {
				commit("SET_APP_ALREADY_STARTED", true)
			}
		})

		userRef.on("value", (snapshot) => {
			if (snapshot) {
				const user = snapshot.val();

				if (user.status !== 'disconnected') {
					localStorage.setItem('status', user.status)
				} else {
					firebase
						.database()
						.ref("users")
						.child(state.user.uid)
						.update({
							status: localStorage.getItem('status'),
							last_changed: firebase.database.ServerValue.TIMESTAMP
						})
				}

				if (user.status !== 'disconnected') {
					const status = localStorage.getItem('status') ? localStorage.getItem('status') : "connected";
					commit("SET_USER", {
						uid: uid,
						extension: user.extension || false,
						fullname: user.fullname,
						status: user.status === "disconnected" ? status : user.status,
						email: user.email,
						picture : user.picture,
						signIn : user.signIn,
						create_report : user.create_report
					})
					commit('SET_PRESENCE', user.presence)
				}
			}
		});

		firebase.database().ref("user_actions").child(uid).on('child_added', (snap) => {
			commit("ADD_ACTION", { id: snap.key, ...snap.val()});
		})

		firebase.database().ref("user_actions").child(uid).on('child_removed', (snap) => {
			commit("REMOVE_ACTION", snap.key);
		})

		dispatch('listenSettings')
	},
	updateStatus({ rootState }, status) {
		const firebase = rootState.firebase.firebase;

		if (!firebase || !state.user || Object.keys(state.user).length === 0)  {
			return;
		}

		firebase
			.database()
			.ref("users")
			.child(state.user.uid)
			.update({
				status: status,
				last_changed: firebase.database.ServerValue.TIMESTAMP
			});
	},
	setFirebaseLogged({ commit }, value) {
		commit('SET_LOGGED_FIREBASE', value)
	},
	removeIncomingCall({ rootGetters }, index) {
		const firebase = rootGetters.getFirebase;
		let data = {};
		data[index] = null;
		firebase.database().ref("users").child(state.user.uid).update(data);
	},
	updateUniqueid({ rootGetters }, uniqueid) {
		const user = rootGetters.getUser
		const firebase = rootGetters.getFirebase;
		firebase.database().ref("users").child(user.uid).update({
			signIn : uniqueid
		});
	},
	updateData({ rootState }) {
		let extension = "";
		const firebase = rootState.firebase.firebase;
		if (state.user.localExtension) {
			if (state.user.localExtension.extension && state.user.localExtension.domain) {
				extension = state.user.localExtension.extension + '@' + state.user.localExtension.domain
			}
		}

		const status = localStorage.getItem('status') ? localStorage.getItem('status') : "connected";

		return firebase
			.database()
			.ref("users")
			.child(state.user.uid)
			.update({
				extension: extension,
				fullname: state.user.name + " " + state.user.lastname,
				status: status,
				email: state.user.email,
				picture: state.user.picture,
				create_report : false,
				organization_id : state.user.organization_session.id,
				data_set : state.user.organization_session.data_set
			});
	},
	updateProfilePicture({ rootGetters, commit }, url) {
		const firebase = rootGetters.getFirebase;
		return firebase.database().ref('users').child(state.user.uid).update({
			picture: url
		}).then(() => {
			userapi.changeProfilePicture({ url })
		})
	},
	fetchUserData({ commit, dispatch }) {
		return new Promise((resolve, reject) => {
			userapi.fetchUserData()
				.then((response) => {
					const data = response.data.data

					const extension = (data.user.extensions[0]) ? data.user.extensions[0] : null;

					const roles = []
					Object.keys(data.user.roles).forEach(index => roles.push(data.user.roles[index]))

					commit('SET_USER', {
						...data.user, 
						roles, 
						localExtension: extension
					});

					if (extension) {
						commit('SET_JANUS_CONFIG', extension.janus_server);
					}

					if ('agent' in data) {
						data.agent.name = data.user.name + ' ' + data.user.lastname;
						commit("SET_AGENT", data.agent);
					}

					//localStorage.setItem("integrations", JSON.stringify(data.integrations));
					commit("integrations/setIntegrations", data.integrations, { root: true });

					return resolve(data.firebase);
				})
				.catch((e) => {
					reject(e)
				})
		})
	},
	changePassword({ commit }, password) {
		return new Promise((resolve, reject) => {
			userapi.changePassword({ password: password })
				.then((response) => {
					resolve(response)
				})
				.catch((error) => {
					reject(error)
				})
		});
	},
	changeProfilePicture({ commit }, url) {
		return userapi.changeProfilePicture({ profile_picture: url });
	},
	fetchUsersWithoutAgents({ commit }, id = 0) {
		return userapi.fetchUsersWithoutAgents(id)
			.then((response) => {
				const users = response.data.map((user) => {
					user['@id'] = `/api/users/${user.id}`;
					return user;
				})
				commit('SET_USERS_WITHOUT_AGENT', users);
			})
	},
	setDefaultValues({commit}) {
		commit('SET_USER', defaultValues())
	},
	changeUserPassword({ commit }, {id, password}) {
		return userapi.changeUserPassword(id, password)
	},
	fetchSignInOfUser({ rootGetters }) {
		return new Promise((resolve, reject) => {
			const user = rootGetters.getUser
			const firebase = rootGetters.getFirebase
			firebase.database().ref('users').child(user.uid).child('signIn').once('value', (snap) => {
				return resolve(snap.val() ? true : false)
			})
		})
	},
	forceLogout({ rootGetters }) {
			const user = rootGetters.getUser
			const firebase = rootGetters.getFirebase
			return firebase.database().ref('logout').child(user.uid).set(true)
	},
	setPresence({rootGetters}, value) {
		const user = rootGetters.getUser
		const firebase = rootGetters.getFirebase
		firebase.database().ref('users').child(user.uid).update({
			presence : value
		})
	},
	setCreateReportLogin({rootGetters}) {
		const user = rootGetters.getUser
		const firebase = rootGetters.getFirebase
		firebase.database().ref('users').child(user.uid).update({
			create_report : false,
			organization_id : user.organization_session.id,
      		data_set : user.organization_session.data_set
		})
	},
	async loginAsUser({ dispatch, commit, rootGetters}, email) {
		const { data } = await auth.loginApiKey(email);

		commit('SET_TOKEN', data.token);
		const date = new Date();
		date.setHours(date.getHours() + 12)
		const expires = "expires=" + date.toUTCString();
		document.cookie ="hiper_token=" + data.token + ";" + expires + ";path=/"; 

		const _firebase = rootGetters.getFirebase
		const firebase = await dispatch("fetchUserData");

		// dispatch("saveFirebaseConfiguration", firebase);
		// dispatch("initializeFirebaseApp");
		await _firebase.auth().signInWithCustomToken(data.customToken);
	},
	async removeUserAction({ commit, rootGetters }, key) {
		const user = rootGetters.getUser
		const firebase = rootGetters.getFirebase
		await firebase.database().ref("user_actions").child(user.uid).child(key).remove();
		commit("REMOVE_ACTION", key);
	}
};

export default {
	state,
	getters,
	mutations,
	actions,
};
