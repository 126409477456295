import store from '@/store'

const isIpAddress = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('required'));
    }
    const regExp = /^(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))(:\d{1,5})$/;
    if (!regExp.test(value)) {
      return callback(new Error('Debe ser una dirección IP valida'));
    }
    callback();
}
 
const isGreaterThan = (val) => {
  return (rule, value, callback) =>  {
    if (value <= val) {
      return callback(new Error('El valor debe ser mayor a ' + val));
    }
    callback();
  }
}

const lengthIsGreaterThan = (val) => {
  return (rule, value, callback) =>  {
    if (value.length < val) {
      return callback(new Error('El longitud debe ser mayor a ' + val));
    }
    callback();
  }
}

const lengthIsLessThan = (val) => {
  return (rule, value, callback) =>  {
    if (value.length > val) {
      return callback(new Error('El longitud debe ser mayor a ' + val));
    }
    callback();
  }
}

const atLeastOneNumber = (rule, value, callback) => {
  if (!/[0-9]/.test(value)) {
    return callback(new Error('El valor debe de tener al menos un numero'));
  }
  callback();
}

const atLeastOneLetterMinus = (rule, value, callback) => {
  if (!/[a-z]/.test(value)) {
    return callback(new Error('El valor debe de tener al menos una letra minuscula'));
  }
  callback();
}

const atLeastOneLetterMayus = (rule, value, callback) => {
  if (!/[A-Z]/.test(value)) {
    return callback(new Error('El valor debe de tener al menos una letra mayuscula'));
  }
  callback();
}

const atLeastOneSpecialChar = (rule, value, callback) => {
  if (!/[%$&#\\/*!?¡¿]/.test(value)) {
    return callback(new Error('El valor debe de tener al menos una letra mayuscula'));
  }
  callback();
}

const requiredIf = (index) => {
  return (rule, value, callback) => {
    if (store.getters['form/getFormValue'](index).value && !value) {
      return callback(new Error('Es requerido'))
    }
    callback()
  }
}

export default {
  isIpAddress,
  isGreaterThan,
  atLeastOneNumber,
  lengthIsGreaterThan,
  atLeastOneLetterMinus,
  atLeastOneLetterMayus,
  atLeastOneSpecialChar,
  requiredIf,
  lengthIsLessThan
}