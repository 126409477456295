<template>
  <div>
      <div class="soft-phone" size="400px">
      <div class="container" v-if="presence === 'web'">
        <div class="buttons">
          <button @click="active = 'contacts' "  class="icon" :class="{'active' : active === 'contacts' }"  ><span class="icon-hiperme_iconos92"></span></button>
          <button @click="active = 'callhistory' "  class="icon" :class="{'active' : active === 'callhistory' }"  ><span class="icon-hiperme_iconos93"></span></button>
          <div class="badge">
            <button @click="active = 'voicemail' "  class="icon" :class="{'active' : active === 'voicemail' }"  ><span class="icon-hiperme_iconos94"></span></button>
            <p class="unlisten-messages" v-if="voicemail > 0">{{ voicemail }}</p>
          </div>
          <button @click="onClickKeypad"  class="icon" :class="{'active' : active === 'keypad' || active === 'incall' || active === 'calling' }"  ><span class="icon-hiperme_iconos95"></span></button>
        </div>
        <div class="content" v-if="connected">
          <the-keypad class="center" v-if="active === 'keypad'" :janus="janus" ></the-keypad>
          <the-voicemail class="center" v-else-if="active === 'voicemail'"></the-voicemail>
          <the-call-history v-else-if="active === 'callhistory'" from="corpo"></the-call-history>
          <the-contact-list v-else-if="active === 'contacts'" ></the-contact-list>
          <the-incall class="center" v-else-if="active === 'incall'" :janus="janus" :display-name="displayName" :callerid="callerid" :call-date="callDate" @click.native="automatautomaticClose = false"></the-incall>
          <the-calling class="center" v-else-if="active === 'calling'" :display-name="displayName" ref="calling"></the-calling>
        </div>
        <div v-else class="content">
          <div>
            <p class="text-center">Conectandose, espere por favor</p>
            <div v-loading="!connected" style="background:transparent"></div>
          </div>
        </div>
      </div>
      <div class="container" v-else>
        <p>{{ $t("user-configuration.calls.calls-will-be-received-in-the-application") }}</p>
        <the-presence-configuration></the-presence-configuration>
      </div>
      <el-dialog :visible.sync="modal.incomingcall" width="30%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :append-to-body="true">
        <the-incoming-call :janus="janus" :callerid="callerid" :display-name="displayName" :jsep="jsep" :stop-audio="!modal.incomingcall"></the-incoming-call>
      </el-dialog>
    </div>
    <video ref="sipAudio" autoplay playsinline loop style="display:none"></video>
    <audio ref="audio" loop>
      <source :src="src" type="audio/ogg" />
    </audio>
  </div>
</template>

<script>
import TheKeypad from './TheKeypad'
import TheVoicemail from './TheVoicemail'
import TheCallHistory from './TheCallHistory'
import TheContactList from './TheContactList'
import janus from "@/plugins/janus-proxy"
import createNotification from "@/plugins/notification"
import { mapActions, mapGetters } from 'vuex'
import TheIncall from './TheIncall'
import TheCalling from './TheCalling'
import TheIncomingCall from './TheIncomingCall'
import ThePresenceConfiguration from '../configuration/ThePresenceConfiguration.vue'
import ZohoProxy from "@/plugins/zoho";
export default {
  components : {
    TheIncall,
    TheKeypad,
    TheVoicemail,
    TheCallHistory,
    TheContactList,
    TheCalling,
    TheIncomingCall,
    ThePresenceConfiguration
},
  data() {
    return {
      //janusConf : JSON.parse(localStorage.getItem("janus_configuration")),
      janus : null,
      jsep : null,
      modal : {
        incomingcall : false,
        incall : false,
        calling : false
      },
      callerid : null,
      previusUserStatus : '',
      displayName : null,
      callDate : null,
      automatautomaticClose : true,
      visible : false,
      connected : false,
      tryingToConnect : false,
      src: process.env.VUE_APP_DEFAULT_RINGTONE,
      loadingJanus: false,
      callKey: null,
      zohoProxy : null,
    }
  },
  computed : {
    ...mapGetters({
      user : 'getUser',
      outputDevice : 'getOutputAudioDevice',
      inputDevice : 'getInputAudioDevice',
      volume : 'getOutputAudioVolume',
      hasToReloadJanus : 'reloadJanus',
      voicemail : 'getVoicemail', 
      showSoftphoneModal : 'getShowSoftphoneModal',
      isInCall : 'getCorpoIsInCall',
      tabActive : 'corpocalls/getActive',
      isMainWindow : 'getIsMainWindow',
      presence: 'getPresence',
      janusConf: 'getJanusConfig',
      actions:'getActions',
    }),
    ...mapGetters({
      volume: 'getOutputAudioVolume',
      outputDevice: 'getOutputAudioDevice',
      interactWithPage: 'getInteractWithPage'
    }),
    ...mapGetters('zoho', {
      zohoClientId : 'getClientId',
      zohoClientSecret: 'getClientSecret',
      zohoUri: 'getUri',
      zohoAuthToken : 'getAuthToken',
      zohoListening : 'getListening',
      zohoClick2Call : 'getClick2Call',
    }),
    extension() {
      const stunServer = this.user.localExtension.stunServer ?? null;
      return {...this.user.localExtension, displayname : this.user.localExtension.name, number : this.user.localExtension.extension, stunServer: stunServer }
    },
    show : {
      set(value) {
        this.$store.commit('SET_SHOW_MODAL', value)
      },
      get() {
        return this.showSoftphoneModal
      }
    },
    active : {
      set(value) {
        this.$store.commit('corpocalls/SET_ACTIVE', value)
      },
      get() {
        return this.tabActive
      }
    },
  },
  methods: {
    ...mapActions(['addCallHistory','saveJanus','updateStatus','listenCallHistory','updateCallToLost','setVoicemail', "setIsListeningVoicemail","addCallHistory", "removeUserAction"]),
    async initJanus() {
      const methods = {
        incomingcall : this.incomingcall,
        calling : this.calling,
        accepted : this.accepted,
        hangup : this.hangup,
        notify : this.notify,
      }

      if (!this.janusConf || this.loadingJanus) {
        return
      }

      const _janus = janus.init(this.janusConf.servers, this.janusConf.secret, this.extension, this.extension.stunServer ); 
      this.loadingJanus = true;

      _janus.onSipMessage((message, jsep) => {
        if (message.result) {
          const event = message.result.event
          let data = message.result;
          if (event in methods) {
            methods[event](message.result, jsep)
          }

          if (event === "progress") {
            this.$refs.calling.stop()
          }

          if (event === "accepted" || event === "hangup") {
            this.modal.incomingcall = false
          }

          if (event === 'incomingcall' || event === 'accepted' && this.zohoProxy) {
            this.zohoProxy.setCallId(this.janus.getCallId());
          }

          if (event === 'ringing') {
            data = {
              from : this.user.localExtension.extension,
              id : this.janus.getCallId(),
              to: this.janus.getCallToNumber(),
            }
          }

          if (this.zohoProxy) {
            this.zohoProxy.send(event, data);
          }
        }
      })
    
      if (_janus) {
        _janus.onCallingError((error) => {
          this.$alert(error);
        });

        _janus.onErrorAttachSip((error) => {
          this.$alert(error);
        });

        _janus.attachAudio(this.$refs.sipAudio)
        _janus.attachAudio(document.querySelector("#corpoCallAudio"));
        _janus.onDestroyedSession(() => {
          this.onJanusDestroy();
        });

        try {
          await _janus.startConnection()
          this.tryingToConnect = false
          this.connected = true
          _janus.startPing((connected) => {
            this.connected = connected
            if (!this.connected) {
              _janus.destroy(() => {
                this.onJanusDestroy();
              });
            }
          })
        } catch (err) {
          if (err === "Error connecting to any of the provided Janus servers: Is the server down?") {
            setTimeout(() => {
              this.onJanusDestroy();
            }, 10000)
            return;
          }
        }
      }

      setTimeout(() => {
        this.janus = _janus
        this.$store.commit('SET_IS_JANUS_READY', true)
        this.saveJanus(this.janus)
      }, 5000)
    },
    async incomingcall(result, jsep) {
      this.jsep = jsep
      this.modal.incomingcall = true
      this.show = true;
      this.displayName = result.displayname
      this.callerid = result.username.replace('sip:', '').split('@').shift()
      const id = await this.addCallHistory({
        from: "corpo",
        call: {
          index: this.user.uid,
          displayname: this.displayName,
          number: this.callerid,
          date: new Date().getTime(),
          type: "ingoing",
        },
      })
      this.callKey = id;
      createNotification(this.$t("call.incoming-call-notification", { callerid : this.displayName }))
      this.play();
    },
    accepted() {
      this.callDate = new Date()
      this.modal.calling = false
      this.modal.incall = true
      this.previusUserStatus = this.user.status
      this.active = 'incall'
      this.show = true
      this.updateStatus('busy')
      this.$store.commit('SET_IS_IN_CALL', true)
      this.$store.commit('SET_IS_IN_CALL_WITH', this.callerid);
      if (/\*97/.test(this.callerid)) {
        if (this.callerid === "*97") {
          this.setIsListeningVoicemail(true)
        }
      }
      this.stop();
    },
    hangup(result) {
      if (result.code === 487 && this.callKey) {
        this.updateCallToLost({ from: "corpo", id: this.callKey})
        this.$notify({
          title: this.$t('call.lost-call'),
          message: this.$t('call.lost-call-message', { displayname: this.displayName, callerid : this.callerid,  }),
          duration: 0,
          position: "bottom-right",
        })
      }

      this.callDate = null;
      this.callKey = null
      this.callerid = null
      this.displayName = null
      this.modal.incall = false
      this.modal.calling = false
      this.updateStatus(this.previusUserStatus ?? "connected");
      this.setIsListeningVoicemail(false) 
      this.$store.commit('SET_IS_IN_CALL', false)
      this.$store.commit('SET_IS_IN_CALL_WITH', null);
      this.$store.commit("corpocalls/SET_CALLING", false);
      this.active = 'keypad'
      this.stop();
    },
    onClickKeypad() {
      this.active = !this.modal.incall ? 'keypad' : 'incall'
      this.active = this.modal.calling ? 'calling' : this.active
    },
    async calling() {
      this.active = 'calling'
      this.callerid = this.janus.getCallToNumber();
      this.displayName = this.janus.getCallToNumber() === '*97' ? 'Voicemail' : this.janus.getCallToNumber()
      await this.addCallHistory({
        from: "corpo",
        call: {
          index: this.user.uid,
          displayname: this.displayName,
          number: this.janus.getCallToNumber(),
          date: new Date().getTime(),
          type: "outgoing",
        }
      })
      this.modal.calling = true
      this.$store.commit("corpocalls/SET_CALLING", true);
    },
    notify(message) {
      const methods = {
        ['message-summary'] : this.messageSummary,
      }

      const notify = message.notify

      if (notify in methods) {
        methods[notify](message)
      }
    },
    messageSummary(message) {
      const messages = parseInt(message.content.split('\r\n')[2].split(" ")[1].split("/").shift())
      this.setVoicemail(messages)
    },
    play() {
      this.$refs.audio.play()
    },
    stop() {
      this.$refs.audio.pause()
      this.$refs.audio.currentTime = 0
    },
    onJanusDestroy() {
      this.loadingJanus = false;
      if (this.modal.incomingcall){
        this.modal.incomingcall = false;
      }
      if (this.janus) {
        this.janus.stopAllTracks();
        this.janus = null;
        this.connected = false
      }
      if (this.isMainWindow && this.presence === "web") {
        this.initJanus();
      }
    }
  },
  created() {
    this.listenCallHistory({ from : 'corpo' })

    setTimeout(() => {
      this.visible = true
    }, 2000)

    if (this.isInCall) {
      this.active = 'incall'
    }

  },
  async mounted() {
    await this.initJanus();
    this.previusUserStatus = this.user.status
    this.$refs.sipAudio.volume = this.volume
    if (this.janus) {
      this.janus.attachAudio(document.querySelector("#corpoCallAudio"));
    }
    if (typeof this.$refs.sipAudio.sinkId !== "undefined") {
      this.$refs.sipAudio.setSinkId(this.outputDevice)
    }
  },
  watch : {
    volume(value) {
      this.$refs.sipAudio.volume = value
    },
    outputDevice(value) {
      if (typeof this.$refs.sipAudio.sinkId !== "undefined") {
        this.$refs.sipAudio.setSinkId(value)
        if (this.$refs.audio.setSinkId) {
          this.$refs.audio.setSinkId(value)
        }
      }
    },
    inputDevice(value) {
      this.janus.changeMicrophone(value)
    },
    hasToReloadJanus(value) {
      if (value) {
        this.initJanus()
      }
      if (typeof this.$refs.sipAudio.sinkId !== "undefined") {
        if (this.$refs.sipAudio.setSinkId) {
          this.$refs.sipAudio.setSinkId(this.outputDevice)
        }
      }
    },
    isInCall(value) {
      if (value) {
        this.active = 'incall'
      }
    },
    isMainWindow(value) {
      if (!value) {
        this.janus.destroy();
      } else {
        this.initJanus();
      }
    },
    /* isMainWindow(value) {
      if (!value) {
        if (this.janus) {
          this.janus.destroy()
        }
      } else {
        if (!this.janus) {
          if (this.user.extension) {
            setTimeout(() => {
              this.initJanus()
            }, 2000)
          }
        }
      }
    }, */
    presence(value) {
      if (value === "web") {
        if (!this.janus) {
          if (this.user.extension) {
            setTimeout(() => {
              this.initJanus()
            }, 2000)
          }
        }
      } else {
        if (this.janus) {
          this.janus.destroy(() => {
            this.onJanusDestroy();
          });
        }
      }
    },
    zohoAuthToken(value) {
      if (value) {
        this.zohoProxy = ZohoProxy();
        this.zohoProxy.setUser(this.user.localExtension.extension);
        this.zohoProxy.callControls();
        this.zohoProxy.toggleClick2Dial(this.zohoClick2Call);
        this.zohoProxy.toggleIntegration();
      } else {
        this.zohoProxy.setEnabled(false);
      }
    },
    actions(values) {
      values
        .filter(value => value.type === 'call')
        .forEach(action => {
          let actionType = action.action
          if (this.janus) {
            if (action.action in this.janus) {
              if (this.modal.incomingcall && actionType === 'hangup') {
                actionType = 'decline';
              }
              if (actionType === "answer") {
                this.janus[actionType](this.jsep);
              } else {
                this.janus[actionType](action.data);
              }
            }
          }
          this.removeUserAction(action.id)
        })
    }
  }
}
</script>

<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
  }

  .keypad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--column);
    grid-auto-rows: minmax(10px, auto);
  }

  .icon {
    border:none;
    outline: none;
    font-size: 30px;
    background: transparent;
    color: var(--blue-grey)
  }

  .icon.active {
    color: white;
  }

  .clasic .icon.active {
    color: var(--hiperpbx-green);
  }

  .buttons {
    width: 100%;
    display: inline-flex;
    justify-content: space-around;
    margin-bottom: var(--column)
  }

  .light .icon {
      color: var(--hiperpbx-green);
  }

  .light .icon.active {
    color: var(--hiperpbx-dark-green)
  }

  .center {
    margin: 0 auto;
  }

  .the-keypad-button.incall {
    background-color: var(--hiperpbx-dark-green)
  }
  .badge {
    position: relative
  }

  .unlisten-messages {
    bottom: 0px;
    position: absolute;
    right: 0;
    margin-bottom: 0;

    background-color: #f56c6c;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #fff;
  }

  .soft-phone {
    width: var(--softphone-width);
  }
  .big {
    padding: 16px;
  }
</style>