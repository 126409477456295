<template>
  <div>
    <i slot="reference" class="fas fa-signal" :class="{ poor }"></i>
    <el-dialog title="Conexión RTC" :visible.sync="dialogVisible" :append-to-body="true">
      <p>{{ janus.server() }}</p>
      <div class="cards">
        <el-card shadow="always" class="card">
          <p class="title">Bitrate (Kbps)</p>
          <ul>
            <li v-for="(b, index) in bitrate" :key="index">
              <p>{{ b.label }}</p>
              <p>{{ b.value.toFixed() }}</p>
            </li>
          </ul>
        </el-card>
        <el-card shadow="always" class="card">
          <p class="title">Packet Loss</p>
          <ul>
            <li v-for="(b, index) in packetLost" :key="index">
              <p>{{ b.label }}</p>
              <p>{{ b.value.toFixed(2) }}%</p>
            </li>
          </ul>
        </el-card>
        <el-card shadow="always" class="card">
          <p class="title">Jitter (ms)</p>
          <ul>
            <li v-for="(b, index) in jitter" :key="index">
              <p>{{ b.label }}</p>
              <p>{{ b.value.toFixed() }}</p>
            </li>
          </ul>
        </el-card>
         <el-card shadow="always" class="card">
          <p class="title">RTT (ms)</p>
          <ul>
            <li v-for="(b, index) in roundTripTime" :key="index">
              <p>{{ b.label }}</p>
              <p>{{ b.value }}</p>
            </li>
          </ul>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    stats: {
      require: true,
    },
    janus: {
      required: true,
    }
  },
  data() {
    return {
      dialogVisible: false,
    }
  },
  computed: {
    poor() {
      if (!this.stats) return false;

      let value = 0;

      Object.keys(this.stats.streams).forEach((index) => {
        const connection = this.stats.streams[index]; 

        if (connection.packetsLoss) {
          if (connection.packetsLoss >=3 && connection.packetsLoss <= 6) {
            value++;
          }
        }

        if (connection.jitter) {
          const jitter = connection.jitter * 1000;
          if (jitter > 20 && jitter < 30) {
            value++;
          }
        }

        if (connection.roundTripTime) {
          const roundTripTime = connection.roundTripTime * 1000;
          if (roundTripTime >= 180 && roundTripTime <= 250) {
            value++;
          }
        }
      });

      return value !== 0;
    },
    bad() {
      if (!this.stats) return false;
      let value = 0;

      Object.keys(this.stats.streams).forEach((index) => {
        const connection = this.stats.streams[index]; 
        
        if (connection.packetsLoss) {
          if (connection.packetsLoss > 6) {
            value++;
          }
        }

        if (connection.jitter) {
          const jitter = connection.jitter * 1000;
          if (jitter > 30) {
            value++;
          }
        }

        if (connection.roundTripTime) {
          const roundTripTime = connection.roundTripTime * 1000;
          if (roundTripTime > 250) {
            value++;
          }
        }

      });

      return value !== 0;
    },
    packetLost() {
      if (!this.stats) return [];

      return Object.keys(this.stats.streams).map((index) => {
        return { value: this.stats.streams[index].packetsLoss, label: this.stats.streams[index].direction }
      });
    },
    jitter() {
      if (!this.stats) return [];

      return Object.keys(this.stats.streams).map((index) => {
        return { value: this.stats.streams[index].jitter * 1000, label: this.stats.streams[index].direction }
      });
    },
    roundTripTime() {
      if (!this.stats) return [];

      return Object.keys(this.stats.streams).map((index) => {
        const value = isNaN(this.stats.streams[index].roundTripTime) ? "-" : this.stats.streams[index].roundTripTime.toFixed() * 1000
        return { value, label: this.stats.streams[index].direction }
      });
    },
    bitrate() {
      if (!this.stats) return [];

      return Object.keys(this.stats.streams).map((index) => {
        return { value: this.stats.streams[index].bitrate / 1024, label: this.stats.streams[index].direction }
      });
    }
  }
}
</script>

<style scoped>
.fa-signal {
  color: var(--green);
  cursor: pointer;
}

.fa-signal.poor {
  color: var(--orange);
}

.fa-signal.bad {
  color: var(--red)
}

.cards {
  display: inline-flex;
  width: 100%;
  gap: var(--column);
  justify-content: center;

}

.card {
  min-width: 100px;
}

.title {
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}

p {
  text-align: center;
}

ul {
  display: inline-flex;
  width: 100%;
  gap: var(--column);
}

li {
  list-style: none;
}
</style>